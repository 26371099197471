<template>
  <div>
    <NavBar name="巡检情况"></NavBar>
    <div class="datapreview">
      <el-form :inline="true" class="margintop30">
        <div>
          <el-form-item>
            <myInput @clear="getList" @keydown="$keyDownSearch($event, getList)" v-model="form.worker_name"
              placeholder="请输入上报人姓名">
              <template #pre>上报人姓名:</template>
            </myInput>
            <!-- <el-input placeholder="请输入上报人姓名"  clearable v-model="form.worker_name"></el-input> -->
          </el-form-item>
          <el-form-item>
            <myInput @clear="getList" @keydown="$keyDownSearch($event, getList)" v-model="form.abnormal_worker_name"
              placeholder="请输入处理人姓名">
              <template #pre>处理人姓名:</template>
            </myInput>
            <!-- <el-input placeholder="请输入处理人姓名" clearable v-model="form.abnormal_worker_name"></el-input> -->
          </el-form-item>
          <el-form-item>
            <el-select @change="getList(1)" v-model="form.abnormal_status" clearable placeholder="处理情况">
              <el-option :value="20" label="已处理"></el-option>
              <el-option :value="10" label="未处理"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-date-picker type="daterange" value-format="yyyy-MM-dd"
              @change="$timeChange($event, form, 'beginTime', 'endTime')" v-model="date"></el-date-picker>
          </el-form-item>
          <el-form-item>
            <MyButton title="搜索" @click="getList">
              <template slot="preImage">
                <img src="@/assets/img/icon/search.png" alt />
              </template>
            </MyButton>
          </el-form-item>

          <el-form-item>
            <MyButton :accessId="847227" title="情况类型设置" @click="getAbnormal">
              <template slot="preImage">
                <img src="@/unit/img/st.png" alt />
              </template>
            </MyButton>
          </el-form-item>
          <el-form-item>
            <MyButton :accessId="847228" title="导出" @click="exportDataEvent">
              <template slot="preImage">
                <img src="@/unit/img/down.png" alt />
              </template>
            </MyButton>
          </el-form-item>
        </div>
      </el-form>
      <div class="pub-table">
        <el-table :data="tableData" v-loading="loading">
          <el-table-column label="上报时间" prop="create_at"></el-table-column>
          <el-table-column label="上报人" prop="worker_name"></el-table-column>
          <el-table-column label="点位" prop="inspection_sort"></el-table-column>
          <el-table-column label="位置" prop="abnormal_address"></el-table-column>
          <el-table-column label="情况类型" prop="abnormal_title"></el-table-column>
          <el-table-column label="处理情况" prop="create_at">
            <template #default="{ row }">
              <span v-if="row.abnormal_status == 20">已处理</span>
              <span v-else style="color: #e90000">未处理</span>
            </template>
          </el-table-column>
          <el-table-column label="处理人" prop="abnormal_worker_name"></el-table-column>
          <el-table-column label="处理时间" prop="inspection_time"></el-table-column>
          <el-table-column label="操作" prop="create_at">
            <template #default="{ row }">
              <pop :accessId="847229" tips="详情" @myclick="goUrl(row)">
                <img class="icon" src="@/assets/img/icon/xiangqing.png" alt="" />
              </pop>
            </template>
          </el-table-column>
        </el-table>
        <div class="apiPage">
          <el-pagination next-text="下一页" prev-text="上一页" layout="total, prev, pager, next" :total="total" :page-size="10"
            :current-page="form.pageNum" @current-change="getList"></el-pagination>
        </div>
      </div>
    </div>
    <!-- 添加------ -->
    <el-dialog title="情况类型设置" :visible.sync="settingvisible" width="880px" :close-on-click-modal="false">
      <el-form :model="abnormalForm" ref="setruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="情况类型" prop="region">
          <div class="boxList">
            <template v-if="abnormalForm && abnormalForm.values">
              <div class="list" style="display: flex; margin: 10px 0" v-for="(domains, index) in abnormalForm.values
                .deviceAbnormalList" :key="index">
                <el-form-item :prop="`values.deviceAbnormalList[${index}].content`" :rules="{
                  required: true,
                  message: '请输入情况类型',
                  trigger: 'blur',
                }">
                  <el-input v-model="domains.content" class="public-input" placeholder="请输入情况类型"></el-input>
                </el-form-item>
                <MyButton @click="delAbnormal(index)" left>删除</MyButton>
              </div>
            </template>
            <div>
              <MyButton @click="addAbnormal">添加</MyButton>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <MyButton title="取消" @click="settingvisible = false"></MyButton>
        <MyButton title="确认" type="primary" class="offset" @click="abnormalConfirm"></MyButton>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      loading: false,
      tableData: [],
      form: {
        pageNum: 1,
        pageSize: 10,
        worker_name: "",
        abnormal_worker_name: "",
      },
      type: "",
      total: 0,
      page: 1,
      date: "",
      settingvisible: false,
      abnormalForm: {
        values: {
          deviceAbnormalList: [],
        },
        describe: "异常问题设置",
        key: "equipmentTplSetting",
      },
    };
  },
  created () {
    this.getList();
  },
  methods: {
    exportDataEvent () {
      this.$exported(
        "/inspection_task_log/export",
        this.form,
        "异常问题列表.xlsx"
      );
    },
    getList (e = 1) {
      this.loading = true;
      this.form.pageNum = e;
      this.tableData = [];
      this.$request
        .HttpPost("/inspection_task_log/list", this.form)
        .then((res) => {
          this.loading = false;
          if (res.data) {
            this.tableData = res.data.list;
            this.total = res.data.total;
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    addAbnormal () {
      this.abnormalForm.values.deviceAbnormalList.push({
        content: "",
      });
    },
    delAbnormal (index) {
      this.abnormalForm.values.deviceAbnormalList.splice(index, 1);
    },
    getAbnormal () {
      this.$request
        .HttpGet("/setting/edit", {
          key: "equipmentTplSetting",
        })
        .then((res) => {
          if (res.data) {
            this.abnormalForm = res.data;
          }
          this.settingvisible = true;
        });
    },
    abnormalConfirm () {
      this.$request.HttpPost("/setting/add", this.abnormalForm).then((res) => {
        this.$common.notifySuccess("设置成功");
        this.settingvisible = false;
      });
    },
    goUrl (item) {
      this.$openNewTab(`abnormalDetail?id=${item.log_id}`);
      // this.$router.push(`abnormalDetail?id=${item.log_id}`)
    },
    printEvent () {
      this.$refs.xTable1.print();
    },
  },
};
</script>
<style lang="scss" scoped>
.datapreview {
  margin-top: 20px;
}

/deep/.el-form--inline {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pub-table {
  height: calc(100vh - 250px);
}
</style>
